/**
 * Interface between application's report and the report API.
 */
export default class ReportParameters {

  constructor (columns, values, delimiter) {
    this.columns = columns
    this.values = values
    this.delimiter = delimiter
  }
  
}